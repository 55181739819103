import '@Components/Pages/Contact/styles.scss'

import { Field, Form } from 'react-final-form'
import React, { useContext, useState } from 'react'

import { AuthContext } from '@Contexts/Auth'
import { Col } from 'react-bootstrap'
import MenuHeader from '@Components/shared/MenuPieces/MenuHeader'
import { ReCAPTCHAContainer } from '@Components/shared/ReCAPTCHA'
import { httpPost } from '@Services/API'
import { isValidEmail } from '@Helpers/validators'
import { logError } from '@Services/logging'
import { updateTimestamp } from '@Services/Credentials'

const required = value => (value ? undefined : 'Required')

const validateEmail = value => isValidEmail(value) ? undefined : 'Invalid Email'

const Contact = () => {
  const [reCaptchaVerified, setReCaptchaVerified] = useState(false)
  const [reCaptchaError, setReCaptchaError] = useState(false)
  const [emailSubmited, setEmailSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const { state } = useContext(AuthContext)

  const onSubmit = async (values) => {
    setReCaptchaError(!reCaptchaVerified)

    if (reCaptchaVerified) {
      setLoading(true)
      try {
        await httpPost('/contact', values, state.token)
        updateTimestamp()
        setEmailSubmitted(true)
      } catch (error) {
        logError(error)
      }
      setLoading(false)
    }
  }

  return <div className='contact'>
    <MenuHeader title='Contact Us' />
    <Col
      md={{ span: 8, offset: 2 }}
      sm={{ span: 10, offset: 1 }}
      className='contact-text'
    >
      <p>Call CarVantedge: <a href='tel:800-539-2277'>(800) 539-2277</a></p>
      <p>or</p>
      <p>Send us a message with our email contact form.</p>
    </Col>
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => <form onSubmit={handleSubmit}>
        <Col
          md={{ span: 8, offset: 2 }}
          sm={{ span: 10, offset: 1 }}
          className='contact-input'
        >
          <Field name='name' validate={required}>
            {({ input, meta }) => <div className='contact-form-input-field'>
              <label htmlFor='name-field'>Name*</label>
              <input id='name-field' {...input} data-testid='name-field' type='text' placeholder='Name' />
              {meta.error && meta.touched && <div data-testid='name-field-error' className='field-error'>{meta.error}</div>}
            </div>}
          </Field>
          <Field name='email' validate={validateEmail}>
            {({ input, meta }) => <div className='contact-form-input-field'>
              <label htmlFor='email-field'>E-mail*</label>
              <input id='email-field' {...input} data-testid='email-field' type='text' placeholder='E-mail' />
              {meta.error && meta.touched && <div data-testid='email-field-error' className='field-error'>{meta.error}</div>}
            </div>}
          </Field>
          <Field name='subject' validate={required}>
            {({ input, meta }) => <div className='contact-form-input-field'>
              <label htmlFor='subject-field'>Subject*</label>
              <input id='subject-field' {...input} data-testid='subject-field' type='text' placeholder='Subject' />
              {meta.error && meta.touched && <div data-testid='subject-field-error' className='field-error'>{meta.error}</div>}
            </div>}
          </Field>
          <Field name='message' validate={required}>
            {({ input, meta }) => <div className='contact-form-input-field'>
              <label htmlFor='message-field'>Message*</label>
              <textarea id='message-field' {...input} data-testid='message-field' type='' placeholder='Message' />
              {meta.error && meta.touched && <div data-testid='message-field-error' className='field-error'>{meta.error}</div>}
            </div>}
          </Field>
          <ReCAPTCHAContainer error={reCaptchaError} onError={setReCaptchaError} onVerify={setReCaptchaVerified} />
          {emailSubmited && <div data-testid='submission-confirmation' className='contact-form-success-message'>Your email has been sent!</div>}
        </Col>
        {loading && <div data-testid='loading' className='loading-ring-container'><div className='loading-ring'></div></div>}
        <button data-testid='submit-button' className='contact-form-submit-button' type='submit'>Submit</button>
      </form>
      } />
  </div>
}

export default Contact
